@import '../../../../global.scss';

@media (prefers-reduced-motion: no-preference) {

  .transliterBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    & input {
      border-radius: 5px;
      padding: 5px;
      margin: 10px 5px 0 0;
    }
  }

  .inputBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 0 20px 0;
  }

}