@import '../../global.scss';

.container {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: white;
  padding: 20px;
}

.header {
  margin: 0 0 20px 0;
  border-bottom: 2px solid white;
}

.content {
  flex: 1 1 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  & p {
    margin: 0 0 10px 0;
  }
}
