@import '../../../../global.scss';


.logoBox {
  display: flex;
  flex-direction: row;
  align-items: center;
}

p code {
  color: #61dafb;
}

.logo,
.logoBack {
  height: 30vmin;
  pointer-events: none;
}

.logoBack {
  margin: 0 0 0 -10px;
}

@media (prefers-reduced-motion: no-preference) {
  .logo {
    animation: logoSpin infinite 5s linear;
  }

  .logoBack {
    animation: logoSpinBack infinite 5s linear;
  }
}

.link {
  color: #61dafb;
}

@keyframes logoSpin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes logoSpinBack {
  from {
    transform: rotate(330deg);
  }
  to {
    transform: rotate(-30deg);
  }
}
