@import '../../../../global.scss';

.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    & h2 {
        margin: 0 0 10px 0;
    }
}

.img {
    fill: white;
    cursor: pointer;
}

.info {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.text {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 15px;
}